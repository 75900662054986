/*
Script by : Clem
----------------------------------------
||          Slate management          ||
----------------------------------------
*/

$(document).ready(function() {

    // Au clic sur un choix de pièce
    $('#modal_pieces .modal-piece').click(function (e) { 
        e.preventDefault();

        // Refermer la modale
        $('#modal_pieces').modal('hide');
        
        // Récupérer la piece cliquée
        piece = $(this).data('piece');
        if(debug_mode) { console.log('Switch to room : '+piece); }
        
        // Enregistrer la pièce choisie dans un cookie
        $.cookie(
            'loaded_room', piece, {
              expires: 365,
              path: '/'
            }
        );

        // Si le cookie de ref chargée existe, charger dans le simulateur
        if($.cookie('loaded_ref') != undefined) {
            full_ref = 'Piece '+piece+' '+$.cookie('loaded_ref');
            if(debug_mode) { console.log('Sending ref to simulator : '+full_ref); }
            iframe.contentWindow.postMessage({
                type: 'loadModel',
                ref: full_ref
            }, '*');
        }
    });

    // Fermer message d'aide au clic sur X
    $('.message_sol_container .close_message').click(function (e) { 
        $('.message_sol_container').removeClass('visible');
    });

    $('.menu_bas_gauche_container .switch_button').click(function (e) { 
        container = $('.menu_bas_gauche_container');
        if(container.hasClass('folded')) {
            container.removeClass('folded');
        } else {
            container.addClass('folded');
        }
    });

});