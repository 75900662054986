
function PhpSessionWrite(name,value) {

	$.ajax({
	type: 'GET',
	url: '/admin/php/ajax_php_session_write.php?'+name+'='+value,
	success: function(data) {
		if(debug_mode == true) { console.log('Variable de session définie : '+data); } },
	error: function() {
		console.log('Echec lors de la création de la variable de session : '+data); }
	});
};

function SelectText(element) {
    var text = document.getElementById(element);
    if ($.browser.msie) {
        var range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
    } else if ($.browser.mozilla || $.browser.opera) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
    } else if ($.browser.safari) {
        var selection = window.getSelection();
        selection.setBaseAndExtent(text, 0, text, 1);
    }
}