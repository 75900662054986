/*
Script by : Clem
----------------------------------------
||          Slate management          ||
----------------------------------------
*/

$(document).ready(function() {

    // ------- INITIALISATION -------
    last_ref = '';
    first_click = 1;
    // ------- GESTION NAVIGATION -------
    
    // Au clic sur une catégorie, charger la slate suivante
    $('.navigation_container').on('click', '.slate.current > .slate:not(.simulator_link)', function() { 
        $(this).parent().children('.slate').removeClass('next').addClass('hide');
        $(this).parent().removeClass('current').removeClass('hide').addClass('prev');
        $(this).removeClass('hide').addClass('current');
        $('.hide .next').removeClass('next');

        if(first_click) {
            first_click = 0;
            $('.navigation_container > .infos').addClass('hidden');
            $('.logo_bolon').addClass('hidden');
            $('.logo').addClass('bottomright');
        }
        
    });

    // Au clic sur retour, revenir à la slate précédente
    $('.navigation_container').on('click', '.slate.current > .back', function() { 
        $(this).parent().parent().find('.slate').removeClass('hide prev current').addClass('next');
        $(this).parent().parent().removeClass('prev').addClass('current');
    });

    // Au clic sur un produit, charger ce produit dans le simulateur
    $('.navigation_container').on('click', '.simulator_link:not(.noclick)', function() { 

        // Changement interface à l'arrivée sur le simulateur
        $('.top_interface').removeClass('hide');
        $('.top_interface').removeClass('hide');
        
        // Récupérer la référence de la pièce
        ref = $(this).data('ref');

        // Sauvegarder la ref actuellement chargée dans un cookie
        $.cookie(
            'loaded_ref', ref, {
              expires: 365,
              path: '/'
            }
        );

        if($.cookie('loaded_room') != undefined) {
            current_room = $.cookie('loaded_room');
        } 

        // Changer le contenu affiché dans le simulateur
        full_ref = 'Piece '+current_room+' '+ref;
        if(full_ref != last_ref) {
            if (debug_mode) { console.log('Sending ref to simulator : '+full_ref); }
            iframe.contentWindow.postMessage({
                type: 'loadModel',
                ref: full_ref
            }, '*');
        } else {
            if (debug_mode) { console.log('Don\'t reload "' + full_ref + '", already loaded...'); }
        }
        last_ref = full_ref;

        // Mettre la class de la ref sur le body
        $('body').removeClass();
        $('body').addClass('ref' + ref);

        // Afficher le simulateur
        $('.simulateur_container').addClass('open');
    });

    // Au clic sur retour dans le simulateur, cacher le simulateur
    $('.simulateur_container').on('click', '.back', function() { 
        $('.simulateur_container').removeClass('open');
    });

    // ------- GESTION OPTIONS -------
});